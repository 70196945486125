import './bootstrap';
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import Swal from 'sweetalert2';
import AOS from 'aos';

try {
    Swiper.use([Navigation, Pagination, Autoplay]);
    
    window.Swiper = Swiper;
    window.Swal = Swal;
    window.AOS = AOS;

    require('slick-carousel-latest/slick/slick');
} catch (err) {
    console.log(err);
}
